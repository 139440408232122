enum Mask {
  Account,
  Date,
  DateTime,
  Kpp,
  MonthYear,
  Number,
  PassportSeriesAndNumber,
  PassportSubdivisionCode,
  PhoneNumber,
  PhoneNumberRu,
  PromoCode,
  Psrn,
  Psrnie,
  Rcbic,
  Tin,
  TinOfLlcOrPtc
}

const {
  Account,
  Date,
  DateTime,
  Kpp,
  MonthYear,
  Number,
  PassportSeriesAndNumber,
  PassportSubdivisionCode,
  PhoneNumber,
  PhoneNumberRu,
  PromoCode,
  Psrn,
  Psrnie,
  Rcbic,
  Tin,
  TinOfLlcOrPtc
} = Mask

const maskaOptionsMapping = new Map([
  [Account, { mask: '#'.repeat(20) }],
  [Date, { mask: '##.##.####' }],
  [DateTime, { mask: '##.##.#### ##:##:##' }],
  [Kpp, { mask: '#'.repeat(9) }],
  [MonthYear, { mask: '####-##' }],
  [Number, { mask: '#', tokens: { '#': { pattern: /\d/, multiple: true } } }],
  [PassportSeriesAndNumber, { mask: '## ## ######' }],
  [PassportSubdivisionCode, { mask: '###-###' }],
  [PhoneNumber, { mask: `+${'#'.repeat(14)}` }],
  [PhoneNumberRu, { mask: `+7${'#'.repeat(10)}` }],
  [PromoCode, { mask: '*', tokens: { '*': { pattern: /[a-zA-Zа-яёА-ЯЁ\d]/, multiple: true } } }],
  [Psrn, { mask: '#'.repeat(13) }],
  [Psrnie, { mask: '#'.repeat(15) }],
  [Rcbic, { mask: '#'.repeat(9) }],
  [Tin, { mask: '#'.repeat(12) }],
  [TinOfLlcOrPtc, { mask: '#'.repeat(10) }]
])

namespace Mask {
  export function getMaskaOptions (mask: Mask): object {
    return maskaOptionsMapping.get(mask)!
  }
}

export default Mask
